import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useCookieConsent } from '../utils/CookieConsentContext'
import { Container } from './Container'

const Footer = () => {
	const { showConsentDialog } = useCookieConsent()
	const { t } = useTranslation()
	return (
		<footer className="footer py-20" aria-labelledby="footer-heading">
			<h2 id="footer-heading" className="sr-only">
				Footer
			</h2>
			<Container>
				<div className="footer-columns">
					<div>
						<div>
							<h3>MyMoney Oy</h3>
							<p>{t('business_id')} 3303458-1</p>
							<p>MyMoney Oy, Urho Kekkosen katu 7b, 00100 Helsinki</p>
						</div>
						<div>
							<h4>{t('footer_address')}</h4>
							<p>{t('business_id')} 3303458-1</p>
							<p>MyMoney Oy, Urho Kekkosen katu 7b, 00100 Helsinki</p>
						</div>
					</div>

					<div>
						<div>
							<h4>{t('footer_contact')}</h4>
							<p>
								<Link to="tel:0931549402">09 31549402</Link>
							</p>
							<p>
								<Link to="mailto:info@mymoney.fi">info@mymoney.fi</Link>
							</p>
						</div>
						<div>
							<h4>{t('footer_login')}</h4>
							<p>
								<Link to="https://pay.mymoney.fi/">
									{t('footer_login_to_pay_link')}
								</Link>
							</p>
						</div>
					</div>

					<div>
						<div>
							<h4>{t('footer_explore')}</h4>
							<ul>
								<li>
									<Link to="/">{t('footer_home_link')}</Link>
								</li>
								<li>
									<Link to="/pay">{t('footer_pay_link')}</Link>
								</li>
								<li>
									<Link to="/yhteytta">{t('footer_contact_link')}</Link>
								</li>
								<li>
									<Link to="/valitukset">
										{t('footer_complaint_handling_link')}
									</Link>
								</li>
								<li>
									<Link to="/tiedot">{t('footer_about_link')}</Link>
								</li>
							</ul>
						</div>
					</div>

					<div>
						<img
							src="/mymoney-favicon.png"
							alt="mymoney small icon"
							height={40}
							className="footer-logo"
						/>
						<div>
							<button
								className="button button--primary"
								onClick={showConsentDialog}
							>
								{t('footer_handle_cookies_button')}
							</button>
						</div>
					</div>
				</div>
				<div className="footer-meta-wrapper">
					<div className="footer-meta">
						<Link to="/kayttoehdot">
							{t('footer_terms_and_conditions_link')}
						</Link>
						<Link to="/evasteet">{t('footer_cookies_link')}</Link>
						<Link to="/tietosuojaseloste">
							{t('footer_privacy_policy_link')}
						</Link>
						<span>© Copyright MyMoney Oy 2025</span>
					</div>
				</div>
			</Container>
		</footer>
	)
}

export default Footer
